<template>
  <div class="mb-20">
    <h2 class="text-2xl font-bold mb-10">Airtel Promotions</h2>

    <nav class="border border-b border-blue-200 px-8 pt-1">
      <ul class="flex flex-wrap items-center w-full">
        <li class="-mb-px pr-20 relative">
          <a
            href="#!"
            class="block border-b cursor-pointer duration-300 font-bold pt-3 pb-4 relative text-blue-400 hover:text-gray-600 text-sm"
            :class="{ 'border-blue-500 text-blue-500 hover:text-blue-500': tab === 'free-data' }"
            @click.prevent="setTab('free-data')"
          >
            <span class="mr-2">Free Data</span>
          </a>
          <span class="absolute h-3 w-1 border-r-2 border-blue-200 right-10 top-1/2 transform -translate-y-1/2" />
        </li>
        <!-- <li class="-mb-px pr-20 relative">
          <a
            href="#!"
            class="block border-b cursor-pointer duration-300 font-bold pt-3 pb-4 relative text-blue-400 hover:text-gray-600 text-sm"
            :class="{ 'border-blue-500 text-blue-500 hover:text-blue-500': tab === 'another' }"
            @click.prevent="setTab('another')"
          >
            <span class="mr-2">Another Promo</span>
          </a>
        </li> -->
      </ul>
    </nav>
    <div class="border">
      <component :is="tab" />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    'free-data': () => import('./FreeData'),
    // another: () => import('./Another'),
  },
  data () {
    return {
      tab: 'free-data',
    }
  },
  mounted() {
    this.tab = this.$route.query.tab || this.tab;
  },
  methods: {
    setTab(tab) {
      this.tab = tab;
      this.$router.push({ ...this.$route, query: { ...this.$route.query, tab } });
    },
  }
};
</script>
